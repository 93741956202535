
import { Vue, Options } from 'vue-class-component';
import vueFilePond from 'vue-filepond';
import dayjs from 'dayjs';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import {
  BaseIcon,
  BaseSelect,
  BaseButton,
  BaseTextInput,
  BaseModal,
  BaseDatePicker
} from '@/lib/components';
import { ExternalPatientReference, Patient } from '@/models';
import { getDobFromISOString, verifyMrn } from '@/helpers/patient.helper';
import { UHB_NHS_NUMBER_KEY } from '@/constants';
import { formattedNHSNumber } from '@/lib/helpers/nhs-number.helper';
import { i18n } from '@/i18n/i18n';
import { EpisodeOfCareResponseData } from '@/models/episode-of-care/episode-of-care.model';
import { usePatientStore } from '@/stores/patient.store';
import { useNotificationStore } from '@/stores/notification.store';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

@Options({
  props: {
    title: {
      type: String,
      default() {
        return i18n.global.t('custom.uhb.consult.assign');
      }
    },
    canEditPatientMrn: {
      type: Boolean,
      default: false
    },
    episodeOfCare: {
      type: Object,
      default: () => ({})
    },
    patient: {
      type: Object,
      default: () => ({})
    },
    clinicTypeOptions: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    BaseIcon,
    BaseDatePicker,
    BaseModal,
    BaseButton,
    BaseSelect,
    BaseTextInput,
    FilePond
  }
})
export default class AssignConsultModal extends Vue {
  episodeOfCare!: EpisodeOfCareResponseData;
  patient!: Patient;
  canEditPatientMrn!: boolean;
  patientStore = usePatientStore();
  notificationStore = useNotificationStore();

  loading = false;
  scheduled_at = '';
  scheduled_location = '';
  mrnError: string | null = null;
  errors!: Record<string, string[]>;

  // Patient MRN
  patientMrnAtClinicProvider = '';
  initialPatientMrnAtClinicProvider = '';
  fileAttachmentId: number | null = null;

  mounted() {
    this.startSyncingPatientMrnAtClinicProvider();
  }

  startSyncingPatientMrnAtClinicProvider() {
    this.syncPatientMrnAtClinicProvider();
    this.$watch(
      'episodeOfCare',
      this.syncPatientMrnAtClinicProvider,
      {
        deep: true
      }
    );
  }

  syncPatientMrnAtClinicProvider() {
    this.patientMrnAtClinicProvider = this.episodeOfCare.patient_mrn_at_clinic_provider || '';
    this.initialPatientMrnAtClinicProvider = this.patientMrnAtClinicProvider;
  }

  get patientMrnAtClinicProviderHasChanged(): boolean {
    return !(this.patientMrnAtClinicProvider === this.initialPatientMrnAtClinicProvider);
  }

  get showNhsNumber() {
    return this.patientStore.showNhsNumber;
  }

  get patientNHSNumber(): string {
    if (this.patient && this.patient.external_patient_references) {
      const nhsEpr = this.patient.external_patient_references.find(
        (epr: ExternalPatientReference) =>
          epr.type ? epr.type.key === UHB_NHS_NUMBER_KEY : false
      );

      if (nhsEpr && nhsEpr.value) {
        return formattedNHSNumber(nhsEpr.value);
      }
    }
    return this.$t('platform.common.none').toString();
  }

  get consultTypeDisplayLabel() {
    return this.episodeOfCare?.ophthalmology_details?.consult_type.name;
  }

  get isLocationVisible() {
    const providerName = this.episodeOfCare?.ophthalmology_details?.provider_title ?? null;
    return providerName === this.$t('platform.common.health-harmonie-organisation-label');
  }

  updateEncounter() {
    this.loading = true;
    this.mrnError = null;
    if (
      this.patientMrnAtClinicProvider &&
      !verifyMrn(this.patientMrnAtClinicProvider)
    ) {
      this.mrnError = this.$t('custom.uhb.consult.spaces-not-allowed') as string;
      return;
    }

    this.$emit('schedule', {
      scheduled_at: this.scheduled_at,
      scheduled_location: this.scheduled_location,
      patient_mrn_at_clinic_provider: this.patientMrnAtClinicProviderHasChanged ?
        this.patientMrnAtClinicProvider : null
    });
    this.loading = false;
  }

  get today() {
    return dayjs().format('YYYY-MM-DD');
  }

  get validateSelection() {
    return (
      this.scheduled_at &&
      (this.scheduled_at >= this.today) &&
      (this.scheduled_location || !this.isLocationVisible) &&
      this.patientMrnAtClinicProvider
    );
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'short');
  }

  close() {
    this.$emit('updateErrorMessage', '');
    this.$emit('close');
  }
}
