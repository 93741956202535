import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "max-w-256 p-10 mx-auto bg-white rounded-lg"
}
const _hoisted_2 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_3 = { class: "bg-gray-100 mt-4 rounded-lg" }
const _hoisted_4 = { class: "flex justify-between" }
const _hoisted_5 = { class: "ml-4 mb-2 pt-4 text-gray-900 text-lg font-semibold" }
const _hoisted_6 = { class: "uppercase" }
const _hoisted_7 = { class: "grid grid-cols-1 -mb-4 ml-4 text-sm font-medium" }
const _hoisted_8 = { class: "grid grid-cols-2 py-2" }
const _hoisted_9 = { class: "grid grid-cols-3 py-4 border-t border-gray-300" }
const _hoisted_10 = { class: "col-span-1 text-gray-400" }
const _hoisted_11 = { class: "col-span-2 text-gray-900" }
const _hoisted_12 = { class: "grid grid-cols-3 py-4 mr-4 border-t border-gray-300" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "col-span-1 text-gray-400" }
const _hoisted_15 = { class: "col-span-2 space-x-2" }
const _hoisted_16 = { class: "col-span-5 text-gray-900" }
const _hoisted_17 = { class: "grid grid-cols-6 py-4 mr-4 border-t border-gray-300" }
const _hoisted_18 = { class: "col-span-1 text-gray-400" }
const _hoisted_19 = { class: "col-span-5 text-gray-900" }
const _hoisted_20 = { class: "grid grid-cols-6 py-4 mr-4 border-t border-gray-300" }
const _hoisted_21 = { class: "col-span-1 text-gray-400" }
const _hoisted_22 = { class: "col-span-5 text-gray-900" }
const _hoisted_23 = { class: "mt-8 space-y-8" }
const _hoisted_24 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_25 = { class: "flex gap-y-8 gap-x-6 grid-cols-1 md:grid-cols-3" }
const _hoisted_26 = { class: "mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseDatePicker = _resolveComponent("BaseDatePicker")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, { onClose: _ctx.close }, {
    default: _withCtx(() => [
      (_ctx.patient)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.patient.last_name), 1),
                  _createTextVNode(" , " + _toDisplayString(_ctx.patient.first_name), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("platform.patient.phone")), 1),
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.patient.contact_number), 1)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    (_ctx.showNhsNumber)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t(`custom.uhb.patient.nhs`)), 1),
                          _createElementVNode("div", _hoisted_15, [
                            _createVNode(_component_BaseIcon, { name: "nhs-logo" }),
                            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.patientNHSNumber), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("platform.patient.dob")), 1),
                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.formatDob(_ctx.patient.date_of_birth)) + " (" + _toDisplayString(_ctx.$t("platform.patient.years", {age: _ctx.patient.age})) + ") ", 1)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("platform.patient.location")), 1),
                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.patient.address && _ctx.patient.address.formatted_address
                ? _ctx.patient.address.formatted_address
                : null), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                (_ctx.episodeOfCare)
                  ? (_openBlock(), _createBlock(_component_BaseTextInput, {
                      key: 0,
                      modelValue: _ctx.episodeOfCare.ophthalmology_details.clinic_configuration_name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.episodeOfCare.ophthalmology_details.clinic_configuration_name) = $event)),
                      label: _ctx.$t('custom.uhb.consult.clinic-type'),
                      class: "text-base font-medium",
                      readonly: "",
                      disabled: ""
                    }, null, 8, ["modelValue", "label"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_BaseTextInput, {
                  modelValue: _ctx.consultTypeDisplayLabel,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.consultTypeDisplayLabel) = $event)),
                  label: _ctx.$t('custom.uhb.consult.consult-type'),
                  class: "text-base font-medium",
                  readonly: "",
                  disabled: ""
                }, null, 8, ["modelValue", "label"])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _withDirectives(_createVNode(_component_BaseSelect, {
                  modelValue: _ctx.scheduled_location,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.scheduled_location) = $event)),
                  label: _ctx.$t('custom.uhb.consult.scheduled-location'),
                  options: [
              // TODO: Remove hardcode options after UHB deadline
              { value: 'Lordswood House', label: 'Lordswood House' },
              { value: 'Monkspath', label: 'Monkspath' },
              {
                value: 'The Hawthorns Surgery',
                label: 'The Hawthorns Surgery',
              },
              { value: 'Scanlans House', label: 'Scanlans House' },
              { value: 'Greet', label: 'Greet' },
            ],
                  placeholder: _ctx.$t('custom.uhb.consult.select-location'),
                  class: "mr-4 w-96",
                  error: _ctx.errors?.scheduled_location?.[0]
                }, null, 8, ["modelValue", "label", "placeholder", "error"]), [
                  [_vShow, _ctx.isLocationVisible]
                ]),
                _createVNode(_component_BaseDatePicker, {
                  modelValue: _ctx.scheduled_at,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.scheduled_at) = $event)),
                  label: _ctx.$t('custom.uhb.consult.scheduled-date'),
                  placeholder: 
              _ctx.$t('platform.common.date-formats.short.placeholder')
            ,
                  class: "w-84",
                  "min-date": _ctx.today,
                  error: _ctx.errors?.scheduled_at?.[0]
                }, null, 8, ["modelValue", "label", "placeholder", "min-date", "error"])
              ]),
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.patientMrnAtClinicProvider,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.patientMrnAtClinicProvider) = $event)),
                label: _ctx.$t('custom.uhb.patient.practice-id'),
                "data-dusk": "patient-practice-id",
                disabled: !_ctx.canEditPatientMrn,
                error: _ctx.mrnError || _ctx.errors?.patient_mrn_at_clinic_provider?.[0]
              }, null, 8, ["modelValue", "label", "disabled", "error"])
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createVNode(_component_BaseButton, {
                disabled: _ctx.loading || !_ctx.validateSelection,
                onClick: _ctx.updateEncounter
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]),
              _createVNode(_component_BaseButton, {
                class: "ml-2",
                color: "ghost",
                disabled: _ctx.loading,
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("platform.common.cancel")), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onClose"]))
}